
.instrucoes{
  box-sizing: border-box;
  height: 8rem;
  width: 12rem;
  top: 0.25rem;
  right: 0.25rem;
  padding: 1rem;
  border: solid 4px #eee;
  position: absolute;
  z-index: 10;
  background-color: #ffffff;
  color: #0f0d0b;
  h1 {
    font-size: 22px; 
  }
}
.cena {
  width: 100%;
  height: 100%;
  position: relative;
  display: grid;
  grid: "sky sky sky" 1fr "side-l building side-r" auto "street street street" 60px/1fr auto 1fr;
  background-image: linear-gradient(to top, #bdc2e8 0%, #bdc2e8 1%, #e6dee9 100%);
  background-image: linear-gradient(-20deg, #fc6076 0%, #ff9a44 100%);
  &::before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background: #ff9a44;
    mix-blend-mode: screen;
    opacity: 0.3;
    z-index: 1;
    -webkit-animation: overlay 10s cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
            animation: overlay 10s cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
  }

  .ceu {
    grid-area: sky;
    position: relative;
    padding: 0 10px;
    h1 {
      font-size: 1.6rem;
      color: #1a1a1a;
      text-align: right;
      margin: 10px 0;
    }
    p {
      text-align: right;
      margin: 0;
    }
  }

  /*.side-l {
    grid-area: side-l;
    position: relative;
  }
  .side-l:before {
    content: "";
    display: block;
    position: absolute;
    width: calc(100% - 130px);
    height: 2px;
    background: #629dd2;
    right: 100px;
    top: 0;
    z-index: 99;
    opacity: 0.5;
  }*/
  .side_r {
    grid-area: side-r;
    position: relative;
  }

  .building_wrap {
    grid-area: building;
    position: relative;
    &::before {
      content: "";
      display: block;
      position: absolute;
      width: calc(100% + 20px);
      height: 10px;
      background: #a5754a;
      top: -10px;
      left: -10px;
      box-shadow: 
        inset 0 1px 0 0 rgba(0, 0, 0, 0.1);
    }
    &.house {

      .floor .door {
        width: 60px;
      }
      &::before {
        content: "";
        display: block;
        position: absolute;
        background-color: transparent;
        width: 65px;/*calc(100% + 20px);*/
        height: 20px;
        top: -70px;
        left: -10px;
        box-shadow: 
          150px 0px rgb(108, 51, 8),
          120px 7px rgb(118, 48, 8),
          150px 7px rgb(118, 48, 8),
          180px 7px rgb(118, 48, 8),
          90px 14px rgb(128, 46, 8),
          130px 14px rgb(128, 46, 8),
          170px 14px rgb(128, 46, 8),
          210px 14px rgb(128, 46, 8),
          60px 21px rgb(138, 44, 8),
          99px 21px rgb(138, 44, 8),
          138px 21px rgb(138, 44, 8),
          177px 21px rgb(138, 44, 8),
          215px 21px rgb(138, 44, 8),
          240px 21px rgb(138, 44, 8),
          30px 28px rgb(148, 40, 8),
          70px 28px rgb(148, 40, 8),
          110px 28px rgb(148, 40, 8),
          140px 28px rgb(148, 40, 8),
          180px 28px rgb(148, 40, 8),
          220px 28px rgb(148, 40, 8),
          250px 28px rgb(148, 40, 8),
          270px 28px rgb(148, 40, 8),
          0px 35px rgb(158, 38, 8),
          40px 35px rgb(158, 38, 8),
          80px 35px rgb(158, 38, 8),
          120px 35px rgb(158, 38, 8),
          160px 35px rgb(158, 38, 8),
          200px 35px rgb(158, 38, 8),
          240px 35px rgb(158, 38, 8),
          280px 35px rgb(158, 38, 8),
          300px 35px rgb(158, 38, 8),
          -30px 42px rgb(168, 36, 8),
          10px 42px rgb(168, 36, 8),
          50px 42px rgb(168, 36, 8),
          90px 42px rgb(168, 36, 8),
          130px 42px rgb(168, 36, 8),
          170px 42px rgb(168, 36, 8),
          210px 42px rgb(168, 36, 8),
          250px 42px rgb(168, 36, 8),
          290px 42px rgb(168, 36, 8),
          330px 42px rgb(168, 36, 8)
          ;
      }
    }
    &::after {
      content: "";
      display: block;
      position: absolute;
      width: calc(100% + 10px);
      height: 15px;
      background: #936942;
      top: -15px;
      left: -5px;
      box-shadow: inset 0 1px 0 0 rgba(100, 100, 100, 0.5);
    }
    .building {
      display: grid;
      grid-template-rows: 80px;
      align-content: end;
      background: #d4e9f4;
      padding-bottom: 0px;
      padding-top: 0px;
      min-height: 80px;
      box-shadow: 
        inset 0 10px 0 0 rgba(0, 0, 0, 0.2), 
        inset 5px 0 0 0 #fff, 
        inset -5px 0 0 0 #fff, 
        inset 6px 0 0 0 rgba(0, 0, 0, 0.2), 
        inset -6px 0 0 0 rgba(0, 0, 0, 0.2), 
        inset 0 -2px 0 0 rgba(255, 255, 255, 0.3);
      padding-left: 10px;
      padding-right: 10px;
    }

    .floor {
      display: grid;
      grid-template-columns: auto;
      grid-template-rows: 50px;
      grid-auto-flow: column;
      height: 80px;
      box-sizing: border-box;
      position: relative;
      padding: 0;
      bottom: 0;
    
      &:first-child {
        &::before {
          bottom: 15px;
        }
        &::after {
          bottom: 10px;
        }
      }
      .window {
        width: 52px;
        margin: 5px 5px 0;
        background: #29211c;
        position: relative;
        border: 2px solid #fff;
      
      
        &::before {
          content: "";
          display: block;
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          background: rgba(255, 255, 255, 0.4);
          transition: height 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
          box-shadow: inset 0 2px 0 0 rgba(0, 0, 0, 0.2);
        }
        &.open::before {
          height: 50%;
        }
        &::after {
          content: "";
          display: block;
          position: absolute;
          width: 100%;
          height: 2px;
          top: 50%;
          background: #fff;
          box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.2);
        }

        &.bt-1{
          background-color: #1877F2;
        }
        &.bt-2{
          background-color: #14e433;
        }
        &.bt-3{
          background-color: #b4f218;
        }
        &.bt-4{
          background-color: #ff00f2;
        }
      }
  
      .door {
        width: 77px;
        margin: 3px 10px 0;
        background: rgb(108, 51, 8);
        position: relative;
        border: 2px solid #fff;
        height: 77px;
        border-bottom: 0px;
        
      }
    }
  }

  .street {
    grid-area: street;
    background: #483f38;
    box-shadow: inset 0 20px 0 0 #3a322d, inset 0 21px 0 0 #483f38, inset 0 30px 0 0 #2b2622, inset 0 31px 0 0 #0f0d0b;
    border-top: 1px solid #0f0d0b;
    position: relative;
  }
  
}
.ui-resizable-handle {
  width: 10px;
  height: 100%;
  position: absolute;
}

.ui-resizable-n {
  height: 70px;
  left: -40px;
  top: -70px;
  width: 438px;
  background: transparent;
  cursor: s-resize;
}

.tree {
  position: absolute;
  bottom: 0;
  background: #a25741;
}
.tree > .particle {
  opacity: 0;
  position: absolute;
  background-color: rgba(96, 196, 84, 0.5);
  -webkit-animation: leaves 3s ease-in infinite;
          animation: leaves 3s ease-in infinite;
  border-radius: 0 50%;
}

.tree1 {
  width: 10px;
  height: 100px;
  left: 10vw;
  box-shadow: inset 0 40px 0 0 rgba(0, 0, 0, 0.2);
}
.tree1:before {
  content: "";
  display: block;
  position: absolute;
  width: 80px;
  height: 80px;
  background: #60c454;
  border-radius: 50%;
  left: -70px;
  top: -50px;
  box-shadow: 30px -40px 0 0 #60c454, 60px 5px 0 0 #60c454, 50px -30px 0 0 #60c454, 15px 10px 0 0 #60c454;
}
.tree1:after {
  content: "";
  display: block;
  position: absolute;
  width: 30px;
  height: 4px;
  background: #a25741;
  right: -28px;
  top: 30px;
  transform: rotate(-20deg);
  box-shadow: inset 5px 0 0 0 rgba(0, 0, 0, 0.2);
}

.tree2 {
  width: 8px;
  height: 80px;
  right: 15vw;
  box-shadow: inset 0 30px 0 0 rgba(0, 0, 0, 0.2);
}
.tree2:before {
  content: "";
  display: block;
  position: absolute;
  width: 50px;
  height: 50px;
  background: #60c454;
  border-radius: 50%;
  left: -50px;
  top: -40px;
  box-shadow: 35px -30px 0 0 #60c454, 25px 15px 0 0 #60c454, 50px -5px 0 0 #60c454, 10px -25px 0 0 #60c454;
}
.tree2:after {
  content: "";
  display: block;
  position: absolute;
  width: 15px;
  height: 4px;
  background: #a25741;
  left: -14px;
  top: 40px;
  transform: rotate(20deg);
}

@-webkit-keyframes leaves {
  0% {
    opacity: 0;
    bottom: 100%;
    transform: rotate(0);
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    bottom: 0;
    transform: rotate(360deg);
  }
}

@keyframes leaves {
  0% {
    opacity: 0;
    bottom: 100%;
    transform: rotate(0);
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    bottom: 0;
    transform: rotate(360deg);
  }
}
.bush {
  position: absolute;
  bottom: 0;
  background: #60c454;
}

.bush1 {
  left: 0;
  width: 30px;
  height: 30px;
  border-radius: 35% 50% 0 0;
}
.bush1:before {
  content: "";
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50% 50% 20% 0;
  right: -16px;
  bottom: 0;
  background: #60c454;
}

.flare {
  position: absolute;
  background: rgba(255, 247, 179, 0.3);
  -webkit-clip-path: polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%);
          clip-path: polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%);
}

.flare1 {
  width: 200px;
  height: 200px;
  top: -80px;
  left: -50px;
  transform: rotate(20deg);
  -webkit-animation: flare 40s ease infinite;
          animation: flare 40s ease infinite;
}

.flare2 {
  width: 150px;
  height: 150px;
  top: 30px;
  left: 80px;
  transform: rotate(120deg);
  -webkit-animation: flare 40s ease infinite;
          animation: flare 40s ease infinite;
  animation-direction: reverse;
}

.flare3 {
  width: 100px;
  height: 100px;
  top: 110px;
  left: 190px;
  transform: rotate(20deg);
  -webkit-animation: flare 30s ease infinite;
          animation: flare 30s ease infinite;
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}

@-webkit-keyframes flare {
  0% {
    transform: rotate(20deg);
  }
  33% {
    transform: rotate(-80deg);
  }
  66% {
    transform: rotate(100deg);
  }
  100% {
    transform: rotate(20deg);
  }
}

@keyframes flare {
  0% {
    transform: rotate(20deg);
  }
  33% {
    transform: rotate(-80deg);
  }
  66% {
    transform: rotate(100deg);
  }
  100% {
    transform: rotate(20deg);
  }
}
@-webkit-keyframes overlay {
  0% {
    opacity: 0.3;
  }
  33% {
    opacity: 0.5;
  }
  50% {
    opacity: 0.4;
  }
  70% {
    opacity: 0.2;
  }
  100% {
    opacity: 0.3;
  }
}
@keyframes overlay {
  0% {
    opacity: 0.3;
  }
  33% {
    opacity: 0.5;
  }
  50% {
    opacity: 0.4;
  }
  70% {
    opacity: 0.2;
  }
  100% {
    opacity: 0.3;
  }
}
/* toggle dark mode 
label {
	width:40px;
	height:20px;
	position: relative;
	display: block;
	background: #ebebeb;
	border-radius: 20px;
	box-shadow: inset 0px 5px 15px rgba(0,0,0,0.4), inset 0px -5px 15px rgba(255,255,255,0.4);
	cursor: pointer;
	transition: 0.3s;
}
label:after {
	content: "";
	width:18px;
	height: 18px;
	position: absolute;
	top:1px;
	left:1px;
	background: linear-gradient(180deg,#ffcc89,#d8860b);
	border-radius: 18px;
	box-shadow: 0px 5px 10px rgba(0,0,0,0.2);
	transition: 0.3s;
}
input {
	width: 0;
	height: 0;
	visibility: hidden;
}
input:checked + label {
	background: #242424;
}
input:checked + label:after {
	left:38px;
	transform: translateX(-100%);
	background: linear-gradient(180deg,#777,#3a3a3a);
}
label:active:after{
	width: 26px;
}
.background {
	width:100vw;
	height: 100vh;
	background: #fff;
	z-index: -1;
	position: absolute;
	transition: 0.3s;
}
input:checked + label + .background {
	background:#242424;
}
label svg {
	position: absolute;
	width: 12px;
	top:4px;
	z-index: 100;
}
label svg.sun {
	left:4px;
	fill:#fff;
	transition: 0.3s;
}
label svg.moon {
	left:24px;
	fill:#7e7e7e;
	transition: 0.3s;
}
input:checked + label svg.sun {
	fill:#7e7e7e;
}
input:checked + label svg.moon {
	fill:#fff;
}
/* end toggle dark mode 
.dark .invertImage{
	filter: invert(1) hue-rotate(180deg);
}
/* Header style 
header.slide-in {
	height: 70px;
  animation: slideIn 0.5s ease-in;
}

@keyframes slideIn {
  from {
    transform: translateY(-50%);
  }
  to {
    transform: translateY(0);
  }
}

header.fixed {
  position: fixed;
  height: 70px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
header.fixed .logo {
  max-width: 200px;
  max-height: 60px;
  transition: all 0.3s ease;
}
header.fixed .p-header {
	padding-top: 0 !important;;
	padding-bottom: 0 !important;
}
/* end Header style */

.f_social_icon a {
	width: 46px;
	height: 46px;
	border-radius: 50%;
	font-size: 14px;
	line-height: 45px;
	color: #858da8;
	display: inline-block;
	background: #F7F8FB;
	text-align: center;
	transition: all .2s linear;
	position: relative;
	margin-right: 14px;
}
.f_social_icon a:hover {
	color: #fff;
	font-weight: bold;
}
.f_social_icon a svg {
	position: absolute;
	top: 15px;
	left: 15px;
}
.f_social_icon a.instagram:hover{
	background: radial-gradient(
		circle farthest-corner at 28% 100%,
		#fcdf8f 0%,
		#fbd377 10%,
		#fa8e37 22%,
		#f73344 35%,
		transparent 65%
	),
	linear-gradient(145deg, #3051f1 10%, #c92bb7 70%);
}
.f_social_icon a.facebook:hover{
	background: #1877F2;
}
.f_social_icon a.linkedin:hover{
	background: #0077B7;
}
/* MODAL 
.modal-wrapper {
  width: 500px;
  height: 600px;
}

.modal {
  background: white;
  height:100%;
  width:100%;
  border-radius: 15px;
		z-index: 1000;
		
}

.modal-content {
	overflow-y: auto;
	max-height: calc(100vh - 20px);
	transform: translate(0%, -50%);
    top: 50%;
}


.modal-body {
  padding-top: 10px;
}

.modal-header {
  display: flex;
  justify-content: flex-end;
  font-size: 25px;
}*/