html {
  box-sizing: border-box;
  font-size: 100%;
}

*, *:before, *:after {
  box-sizing: inherit;   
}

body {
  background-color: $ltGray;
  color: $black;
  font-family: 'Roboto', sans-serif;
  line-height: 1.3;
  margin: 0;
  padding: 0;
  overflow-x: hidden;

  @media #{$mq-1000-up}{
  }
}
.sigmar-one-regular {
  font-family: "Sigmar One", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.noto-sans-font {
  font-family: "Noto Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}
.afacad-font {
  font-family: "Afacad", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}
.font-white {
  color: $white;
}
main {
  padding: 20px;
  background-color: $white;
  border-radius: 15px;
  max-width: 1200px;
  margin: 0 auto;

  @media #{$mq-1000-up}{
      display: flex;
      padding: 1.5rem;
  }
}

.content {
  
  @media #{$mq-1000-up}{
      flex: 3;
  }
}

aside {

  @media #{$mq-1000-up}{
      flex: 1;
  }
}
.modal-content {
  background-color: #38322F !important;
  color: white !important;
  width: 300px !important;
  .close {
    background-color: #38322F !important;
    color: white !important;
  }
}