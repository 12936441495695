section {
  align-items: center;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  //min-height: 900px;
  min-height: 110vh;
  position: relative;
  text-align: center;
  width: 100%;
  text-shadow: var(0, 0 0 transparent), var(0, 0 0 transparent);
}
.container-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  will-change: transform; 
}

.initial-section {
  background-image: url("/assets/images/tyaraju01.jpg");
  background-position: center 0px;
}
.about-section {
  background-image: url("/assets/images/tyaraju02.jpg");
}
.studies-section {
  background-image: url("/assets/images/tyaraju03.jpg");
}
.portfolio-section {
  background-image: url("/assets/images/tyaraju01.jpg");
}
.experiences-section {
  background-image: url("/assets/images/tyaraju02.jpg");
}
.stay-in-touch-section {

}

.title-initial{
}
.title-initial h1,
.title-initial h2{
  font-size: 130px;
}
.title-initial p{
  font-size: 30px;
  font-weight: 300; 
}
.text-shadow {
  text-shadow: #333 0px 0px 7px;
}
.menu-main-nav {
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 1000;
}
button.expand-btn,
button.close-btn {
  background-color: transparent;
  border: white 1px solid;
  border-radius: 50%;
  bottom: 35%;
  cursor: pointer;
  height: 40px;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  transition: var(--trans1,border .4s ease 0s, background-color .4s ease 0s);
  width: 40px;
  z-index: 0;
}
button.expand-btn:before,
button.expand-btn:after,
button.close-btn:before,
button.close-btn:after {
  align-self: stretch;
  content: "";
  flex-grow: 1;
}
button.expand-btn:before,
button.expand-btn:after,
button.close-btn:before,
button.close-btn:after {
  max-width: 0px, 0;
}
button.expand-btn:hover,
button.close-btn:hover {
  background: rgba(255, 255, 255, 0.2);  
  border: transparent 1px solid;
}
.plus-expand {
  color: white;
  font-size: 23px;
  line-height: 1;
  margin-bottom: 0;
  margin: 0;
  position: relative;
  transition: var(--trans2, color .4s ease 0s);
  white-space: nowrap;
}
.icon-finger {
  background: transparent;
  border: 0;
  padding-top: 90px;
}


/* Ocultar conteúdo expandido */
.hidden-content {
  background: rgba(0, 0, 0, 0.8);
  bottom: 0; /* Iniciar da parte inferior da tela */
  color: white;
  display: none;
  height: 100vh;
  left: 0;
  max-height: 0;
  overflow-y: scroll; 
  overflow-x: hidden;
  padding: 0 20px; /* Espaçamento interno inicial */
  position: fixed; /* Para fixar na tela */
  right: 0;
  text-align: left;
  transform-origin: bottom; /* Ponto de origem da animação */
  transition: max-height 0.5s ease-in-out, transform 0.5s ease-in-out; /* Transição suave */
  width: 100%;
  z-index: 2;
}
.hidden-content .container {
  padding: 45px 0;
}
.hidden-content.active {
  display: block;
  animation: expand 0.5s ease-in-out;
  max-height: 100vh; /* Expande para a altura da tela */
}
.hidden-content.closing {
  max-height: 0; /* Recolhe suavemente */
  transform: scaleY(0);
}
@keyframes expand {
  from {
    transform: scaleY(0);
  }
  to {
    transform: scaleY(1);
  }
}

.hidden-content .header {
  min-height: 120px;
}
.hidden-content .close-btn {
  top: 35%;
  bottom: unset;
}
.hidden-content .content p {
  font-size: 19px;
}
/* TRABALHOS RECENTES */
.grid-content {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 24px;
  flex-direction: row;
  overflow-x: auto;
  white-space: nowrap;
  padding-bottom: 10px;
}
.portfolio-item {
  height: 100%;
  left: 0;
  overflow: hidden;
  position: relative;
  top: 0;
  transition: ease-in-out 50ms;
  width: 100%;
}
.portfolio-item img {
  width: 100%;
  height: 100%;
}
.link-projeto {
  /*background: rgba(0, 0, 0, 0.8);//linear-gradient(to top, rgba(0, 0, 0, 1), transparent);*/
  color: #fff;
  display: block;
  height: 100%;
  overflow: hidden;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
}

.link-projeto .image-portfolio {
  display: block;
  height: auto;
  overflow: hidden;
  position: relative;
  transition: transform .6s cubic-bezier(.19,1,.22,1);
  top: 0;
  z-index: 1;
}
.link-projeto:hover .image-portfolio {
  line-height: 1.7em;
  -webkit-transform: translateY(-30px);
  transform: translateY(-30px);
}
.link-projeto .content {
  padding: 1.5rem;
  width: 100%;
}

.link-projeto .item-portfolio-active {
  background-color: #101010;
  display: block;
  overflow: hidden;
  position: absolute;
  transition: transform .6s cubic-bezier(.19,1,.22,1);
  width: 100%;
  z-index: 2;
}
.link-projeto:hover .item-portfolio-active {
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}
.link-projeto .item-portfolio-active .title {
  text-transform: uppercase;
}
.link-projeto .tags {
  display: flex;
  flex-wrap: wrap;
}
.link-projeto .tags span {
  font-size: 14px;
  margin: 0 1rem 0 0;
}
.link-projeto .projeto-by {
  font-size: 12px;
}
.timeline {
  .col-6 {
    margin: auto;
    max-width: 360px;
  }
  .text-right {
    text-align: right;
  }
  .data-timeline {
    position: absolute;
    top: 0px;
    left: 50%;
    text-align: center;
  }
}